import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Footer from '../components/homeNav/footer/Footer';
import Seo from '../components/seo';
import BotNav from '../components/bottomNav';
import PageNav from '../components/homeNav/PageNav';

function BlogIndex({ data }) {
  const siteTitle = data.site.siteMetadata?.title || 'Title';
  const posts = data.allMarkdownRemark.nodes;

  if (posts.length === 0) {
    return (
      <>
        <Seo
          title="Afyabook Healthcare Blog"
          description="Blog on Health and Wellness"
          img="https://www.afyabook.com/static/bcbc5b3e1123cc303539aba298f1482f/66dad/banner-thumb.webp"
          keywords="Online pharmacy, medication therapy management, doctors consultation, remote patient monitoring"
          siteUrl="https://www.afyabook.com/blog"
          canonical="https://www.afyabook.com/blog"
        />
        <PageNav />
        <HeaderContainer>
          <HeroPortfolio>
            <HeroHeader>Our Blog</HeroHeader>
          </HeroPortfolio>
        </HeaderContainer>
        <p>
          No blog posts found.
        </p>
        <BotNav />
        <Footer />
      </>
    );
  }

  return (
    <>
      <Seo
        title="Afyabook Healthcare Blog"
        description="Blog on Health and Wellness"
        img="https://www.afyabook.com/static/bcbc5b3e1123cc303539aba298f1482f/66dad/banner-thumb.webp"
        keywords="Online pharmacy, medication therapy management, doctors consultation, remote patient monitoring"
        siteUrl="https://www.afyabook.com/blog"
        canonical="https://www.afyabook.com/blog"
      />
      <PageNav />
      <HeaderContainer>
        <HeroPortfolio>
          <HeroHeader>Our Blog</HeroHeader>
        </HeroPortfolio>
      </HeaderContainer>
      <ol style={{ listStyle: 'none' }}>
        {posts.map((post) => {
          const title = post.frontmatter.title || post.fields.slug;
          const { tagsWords } = post.frontmatter;

          return (
            <Container>
              <PostContainer data-sal="slide-up" data-sal-easing="ease" key={post.fields.slug}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                        <h2>
                            <Link style={{ textDecoration: 'none' }} to={post.fields.slug} itemProp="url">
                                <span itemProp="headline">{title}</span>
                              </Link>
                          </h2>
                        <p>
                            Posted By Dr. Ibrahim Okich on{post.frontmatter.date}
                          </p>
                        <Keywords style={{ listStyle: 'none' }}>
                            {tagsWords.map((tag) => (
                                <li key={tag}><button>{tag}</button></li>
                              ))}
                          </Keywords>
                      </header>
                  <section style={{ fontSize: '1rem' }}>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: post.frontmatter.description || post.excerpt,
                              }}
                            itemProp="description"
                          />
                      </section>
                  <Link to={post.fields.slug}>
                        <button css="width: 50%; height: 50px; border-radius: 30px; background: #fff;border: 2px solid #71C4DE;">Continue Reading</button>
                      </Link>
                </article>
              </PostContainer>

            </Container>
          );
        })}
      </ol>

      <BotNav />
      <Footer />
    </>
  );
}

export default BlogIndex;

const Container = styled.div`
    width: 100%;
    
`;

const PostContainer = styled.li`
    margin-top: 2rem;
    padding: 1rem 1rem;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    

    @media screen and (min-width: 1000px) {
        max-width: 40%;
    }
`;
const Keywords = styled.ol`
    display: flex;
    font-size: 0.6rem;
    flex-wrap: wrap;

    li {
        
        button {
            background: #E9F3FF;
            margin-right: 1rem;
            border: none;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }

    }
`;
const HeaderContainer = styled.div`
    margin-top: 70px;
    width:100%;
`;
const HeroPortfolio = styled.div`
    background: linear-gradient(to bottom, #1C333A 10%,#1B353C 50%,#1D3238 70%, #187E9C 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    align-items: center;
    height: 50vh;
    padding: 0 1rem;
    
`;
const HeroHeader = styled.h1`
    font-size: clamp(1.5rem,6vw, 3rem);
`;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tagsWords
        }
      }
    }
  }
`;
